CINC = (typeof CINC === 'undefined' ? {} : CINC);

CINC.Shared = {};

(function () {

    $(document).ready(function () {
        CINC.LocalGMT = new Date().getTimezoneOffset() / 60.0 * -1; //convert JS's backwards offset to ISO standard

        SetLanguageCookie();
        InitLingo();
        // pass all paste events as key up
        $(':text, textarea').on('paste', function () {
            $(this).trigger('keyup');
        });
    });

    var SetLanguageCookie = function () {
        if (!CINC.QS) return;

        var lang = CINC.QS.lang;
        if (!lang) return;

        var date = new Date();

        if (lang != 'english')
            date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); // a week?

        $.removeCookie("lang");
        $.cookie("lang", lang, { expires: date, domain: '.' + CINC.Site.DomainName, path: '/', secure: true });
    };

    var InitLingo = function () {
        var secLev = 0;
        if (CINC.User)
            secLev = CINC.User.securityLevel || CINC.User.SecurityLevel;

        if (secLev != 25) return;

        $('[data-lingo=1]').css('cursor', 'help').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var text = encodeURIComponent($(this).html());
            if (!text) text = $(this).val();
            var lingolang = encodeURIComponent($(this).data('lingolang') || 'none');
            window.open('/dashboard/settings/advanced/translations/?language=' + $.cookie('lang') + '&transid=' + text + '&translang=' + lingolang, '_blank');
        });
    };

    var InitWYSIWYG = CINC.Shared.InitWYSIWYG = function (options) {
        $('.js-wysiwyg').each(function () {
            CreateWYSIWYG($(this), options);
            $(this).removeClass('js-wysiwyg');
        });
    };

    var CreateWYSIWYG = CINC.Shared.CreateWYSIWYG = function (textarea, options) {
        options = options || {};
        var setup = options.setup;
        if ($(textarea).data('enforcehttp') === true) {
            options.ensureProtocolInUrl = true;
        }
        if ($(textarea).data('bodyclass')) {
            options.bodyClass = $(textarea).data('bodyclass');
        }
        if ($(textarea).data('hidden')) {
            options.hidden = JSON.parse($(textarea).data('hidden').toLowerCase());
        }

        var toolbar = 'undo redo | styleselect | bold italic | fontsizeselect forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media code';
        if ($(textarea).data('removetoolbaritems')) {
            var toolbarItems = toolbar.split(' ');
            var removeItems = $(textarea).data('removetoolbaritems').split(',');

            for (var i = 0; i < removeItems.length; i++) {
                var removeIndex = toolbarItems.indexOf(removeItems[i]);
                toolbarItems.splice(removeIndex, 1);
            }

            toolbar = toolbarItems.join(' ');
        }
        if ($(textarea).data('toolbar-items')) {
            toolbar = $(textarea).data('toolbar-items');
        }

        var css = $(textarea).data('css');

        tinyMCE.baseURL = window.document.baseURI + 'Scripts/Dashboard/plugins/tinymce/js';

        //  The mceFocus command is the documented way to set focus to an MCE editor, and it works great the first call.
        //  It will scroll the editor into view and set the foucs into the editor so the user can just start typing.
        //  However, it only works completely properly the first time.  On subsequent calls it still sets the focus
        //  into the editor (so the user can immediately start typing), but it will not scroll the editor into view!
        //  Accessing the editorContainer element and calling focus() on it scrolls it into view.
        //  Then we call the mceFocus command to set the focus into the editor for immediate typing.
        if (typeof tinyMCE.setFocus != "function") {
            tinyMCE["setFocus"] = function (textareaId) {
                tinyMCE.get(textareaId).editorContainer.focus(); // Hack for subsequent calls to scroll editor into view.
                tinyMCE.execCommand('mceFocus', false, textareaId);
            };
        }

        if (typeof tinyMCE.isElementTinyMCE != "function") {
            tinyMCE["isElementTinyMCE"] = function (element) {
                if (!(element.id && element.tagName.toLowerCase() === 'textarea')) {
                    return false;
                }

                return tinyMCE.editors.some(function(e) { return e.id === element.id; });
            };
        }

        $(textarea).tinymce({
            branding: false,
            fontsize_formats: "8.5pt 10pt 12pt 14pt 18pt 24pt 32pt 36pt",
            'document_base_url': window.document.baseURI,
            // General options
            'theme': 'modern',
            'plugins': 'link image media code textcolor advlist lists',
            'menubar': false,
            'toolbar': [toolbar],
            'image_advtab': true,
            'convert_urls': false,
            'relative_urls': false,
            'remove_script_host': false,
            'gecko_spellcheck': true,
            'force_br_newlines': true,
            'force_p_newlines': false,
            'convert_newlines_to_brs' : false,
            'forced_root_block': '', // Needed for 3.x
            valid_elements: "*[*]",
            valid_children: "+body[style]",
            extended_valid_elements: "*[*]",
            content_css: css,
            'body_class': options.bodyClass,
            'file_picker_callback': function (callback) {
                var editorWindow = tinymce.activeEditor.windowManager.windows[0];
                editorWindow.hide();
                if (CINC.Dashboard.OpenUploadModal) {
                    CINC.Dashboard.OpenUploadModal(function (file) {
                        AddFileResult(editorWindow, file, options);
                    });
                }
                else {
                    CINC.Dashboard.openGallery('', function (origFileName, fileURL, fileCat, fileDID) {
                        var file = { OrigFileName: origFileName, FullURL: fileURL, FileDID: fileDID, Category: fileCat };
                        AddFileResult(editorWindow, file);
                    }, function () { editorWindow.close(); });
                }
            },
            setup: function (editor) {
                if (setup) setup(editor);
                if(options.keyup)
                    editor.on('keyup', options.keyup);
                editor.on('init', function (initEvent) {
                    if (options.hidden)
                        initEvent.target.hide();
                });
            }
        });
    };

    var getEmojiHTML = function (filterText, emojis) {
        // compile
        var emojiHtml = '<div id="mce-emoji-wrapper" class="cinc-plugin-wrapper">'
            + '{{#each this}}<a class="cinc-emoji" title="{{description}}">\{{value}}</a>{{/each}}'
            + '</div>';
        var emojiTemplate = Handlebars.compile(emojiHtml);
        var noEmojisHtml = '<div id="mce-emoji-wrapper" class="cinc-plugin-wrapper">'
            + '<span>No Emojis to display'
            + '</div>';

        // filter
        if (filterText) {
            filterText = filterText.toLowerCase();
            emojis = _.filter(emojis, function (emoji) {
                return emoji.description.toLowerCase().indexOf(filterText) >= 0;
            });
        }

        // return the html
        if (emojis && emojis.length > 0) {
            return emojiTemplate(emojis);
        }
        else {
            return noEmojisHtml;
        }
    };

    var getDynamicTagsHTML = function (filterText, dynamicTags, id) {
        // compile
        var dynamicTagHtml = '<div id="' + id + '" class="cinc-plugin-wrapper">'
            + '{{#each this}}<a class="cinc-dynamic-tag" title="{{description}}">\{{value}}</a>{{/each}}'
            + '</div>';
        var dynamicTagTemplate = Handlebars.compile(dynamicTagHtml);
        var noDynamicTagsHtml = '<div id="' + id + '" class="cinc-plugin-wrapper">'
            + '<span>No Tags to display'
            + '</div>';

        // filter
        if (filterText) {
            filterText = filterText.toLowerCase();
            dynamicTags = _.filter(dynamicTags, function (tag) {
                return tag.description.toLowerCase().indexOf(filterText) >= 0;
            });
        }

        // return the html
        if (dynamicTags && dynamicTags.length > 0) {
            return dynamicTagTemplate(dynamicTags);
        }
        else {
            return noDynamicTagsHtml;
        }
    };

    var getAttachedPropHTML = CINC.Shared.GetAttachedPropHTML = function (attachedProps, attachedPropsTemplate) {
        // compile
        var attachedPropTemplate = Handlebars.compile(attachedPropsTemplate);

        // return the html
        if (attachedProps && attachedProps.length > 0) {
            var json = {
                props: attachedProps.toJSON()
            };
            return attachedPropTemplate(json);
        }
        else {
            return '';
        }
    };

    var SetupCINCTinyMCEPlugins = CINC.Shared.SetupCINCTinyMCEPlugins = function (options) {
        options = options || {};
        options.dataObject = options.dataObject || new Backbone.Model();

        tinymce.PluginManager.add('cincWYSIWYGPlugins', function (editor, url) {
            // this is where we add all the things to the editor (make the plugin)

            // emoji dialog
            if (options.addEmojis && options.emojiList) {
                var openEmojiDialog = function (editor, emojis) {
                    var cincEmojis = editor.windowManager.open({
                        title: 'CINC Emojis',
                        body: [
                            {
                                type: 'textbox',
                                name: 'searchBox',
                                label: 'Search',
                                tooltip: 'Use this to filter the emoji list below',
                                onkeyup: function (e) {
                                    // change the html...
                                    $('#mce-emoji-wrapper').replaceWith(getEmojiHTML(this.state.data.value, emojis));
                                }
                            },
                            {
                                type: 'container',
                                name: 'emojiList',
                                label: 'Emojis',
                                html: getEmojiHTML('', emojis),
                                onclick: function (e) {
                                    // get the emoji and insert it
                                    editor.execCommand('mceInsertContent', false, $(e.target).html());

                                    // close the widget
                                    cincEmojis.close();
                                }.bind(editor)
                            }
                        ]
                    });
                };

                // Add the buttons that open the dialogs
                editor.addButton('cincEmojis', {
                    icon: 'ico-smiley2',
                    onclick: function () {
                        // Open window
                        openEmojiDialog(editor, options.emojiList);
                    }
                });
            }

            // dynamic tags dialog
            if (options.addDynamicTags && options.dynamicTagsList && options.customTagsList) {
                var openDynamicTagDialog = function (editor, dynamicTags, customTags) {
                    var cincDynamicTags = editor.windowManager.open({
                        title: 'CINC Dynamic Tags',
                        body: [
                            {
                                type: 'textbox',
                                name: 'searchBox',
                                label: 'Search',
                                tooltip: 'Use this to filter the dynamic tag list below',
                                onkeyup: function (e) {
                                    // change the html...
                                    $('#mce-dynamic-tags-wrapper').replaceWith(getDynamicTagsHTML(this.state.data.value, dynamicTags, 'mce-dynamic-tags-wrapper'));
                                    $('#mce-custom-tags-wrapper').replaceWith(getDynamicTagsHTML(this.state.data.value, dynamicTags, 'mce-custom-tags-wrapper'));
                                }
                            },
                            {
                                type: 'container',
                                name: 'dynamicTagList',
                                label: 'Dynamic Tags',
                                html: getDynamicTagsHTML('', dynamicTags, 'mce-dynamic-tags-wrapper'),
                                onclick: function (e) {
                                    // get the emoji and insert it
                                    editor.execCommand('mceInsertContent', false, $(e.target).html());

                                    // close the widget
                                    cincDynamicTags.close();
                                }.bind(editor)
                            },
                            {
                                type: 'container',
                                name: 'customTagList',
                                label: 'Custom Tags',
                                html: getDynamicTagsHTML('', customTags, 'mce-custom-tags-wrapper'),
                                onclick: function (e) {
                                    // get the emoji and insert it
                                    editor.execCommand('mceInsertContent', false, $(e.target).html());

                                    // close the widget
                                    cincDynamicTags.close();
                                }.bind(editor)
                            }
                        ]
                    });
                };

                editor.addButton('dynamicTags', {
                    icon: 'ico-tag5',
                    onclick: function () {
                        // Open window
                        openDynamicTagDialog(editor, options.dynamicTagsList, options.customTagsList);
                    }
                });
            }

            if (options.addVideoWidget) {
                editor.addButton('videoMessaging', {
                    icon: 'ico-facetime-video',
                    onclick: function () {
                        if (options.dataObject.get('isText') && options.dataObject.get('totalLeadsSelected') > CINC.CRMSettings.MMSBulkSendCap) {
                            Backbone.Radio.channel('modal').trigger('showalert', 'Max allowed recipients of MMS (video) messages is ' + CINC.CRMSettings.MMSBulkSendCap + '.');
                        }
                        else {
                            editor.hide();
                            Backbone.Radio.channel('modal').trigger('videoMessagingWYSIWYGCallback', editor, {
                                messageType: options.messageType,
                                selectedVideo: options.dataObject.get('selectedVideo'),
                                manageVideoAttachedDisplay: options.manageVideoAttachedDisplay
                            });
                        }
                        
                    }
                });
            }

            return {
                getMetadata: function () {
                    return {
                        name: "CINC TinyMCE Plugins"
                    };
                }
            };
        });
    };

    var InitTinyMCE = CINC.Shared.InitTinyMCE = function (selector, options) {
        tinymce.baseURL = window.document.baseURI + 'Scripts/Dashboard/plugins/tinymce/js';
        options = options || {
            inline: true,
            menubar: 'edit insert format tools table help',
            toolbar: [
                'bold italic underline strikethrough | styleselect | fontsizeselect forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist',
                'undo redo | link image media code | cincEmojis dynamicTags attachProperty videoMessaging'
            ],
            force_br_newlines: false,
            force_p_newlines: true,
            convert_newlines_to_brs: false,
            forced_root_block: false,
            entity_encoding: '',
            plainTextOnly: false
        };

        var emailBodyConfig = {
            selector: selector,
            'theme': 'modern',
            'image_advtab': true,
            'convert_urls': false,
            'relative_urls': false,
            'remove_script_host': false,
            'gecko_spellcheck': true,
            force_p_newlines: options.force_p_newlines,
            force_br_newlines: options.force_br_newlines,
            convert_newlines_to_brs: options.convert_newlines_to_brs,
            remove_linebreaks: true,
            forced_root_block: options.forced_root_block, // Needed for 3.x
            valid_elements: "*[*] -p",
            valid_children: "+body[style]",
            extended_valid_elements: "*[*]",
            inline: options.inline,
            entity_encoding: options.entity_encoding,
            plugins: [
                'link',
                'image',
                'media',
                'code',
                'textcolor',
                'advlist',
                'lists',
                'cincWYSIWYGPlugins',
                'paste'
            ],
            menubar: options.menubar,
            toolbar: options.toolbar,
            powerpaste_word_import: 'clean',
            powerpaste_html_import: 'clean',
            content_css: [
                '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i'
            ],
            'file_picker_callback': function (callback) {
                var editor = tinymce.activeEditor;
                editor.hide();
                var editorWindow = tinymce.activeEditor.windowManager.windows[0];
                editorWindow.hide();
                if (CINC.Dashboard.OpenUploadModal) {
                    CINC.Dashboard.OpenUploadModal({
                        onUploadCallback: function (file) {
                            editor.show();
                            AddFileResult(editorWindow, file, {});
                        },
                        onCloseCallback: function () {
                            editor.show();
                        }
                    });
                }
                else {
                    CINC.Dashboard.openGallery('', function (origFileName, fileURL, fileCat, fileDID) {
                        var file = { OrigFileName: origFileName, FullURL: fileURL, FileDID: fileDID, Category: fileCat };
                        AddFileResult(editorWindow, file);
                    }, function () { editorWindow.close(); });
                }
            },
            paste_as_text: options.plainTextOnly,
            setup: function (editor) {
                if (options.init)
                    editor.on('init', options.init);
                if (options.keyup)
                    editor.on('keyup', options.keyup);
                if (options.nodechange)
                    editor.on('NodeChange', options.nodechange);
                //need to find the load method
            }
        };
        tinymce.init(emailBodyConfig);
    };

    var AddFileResult = function (editorWindow, file, options) {
        if (!file) {
            editorWindow.close();
            return;
        }

        editorWindow.show();

        var url = file.FullURL;
        if (typeof options !== 'undefined' && typeof options.ensureProtocolInUrl !== 'undefined' && options.ensureProtocolInUrl && url.indexOf('https:') === -1 && url.indexOf('http:') === -1) {
            url = (url.indexOf('//') === 0 ? url : '//' + url);
            url = (url.indexOf('http:') === 0 ? url : 'http:' + url);
        }

        if (file.Category === 'image') {
            if (_.size(editorWindow.find('#src')) !== 0) {
                editorWindow.find('#src').value(url).fire('change');
                editorWindow.find('#alt').value(file.OrigFileName);
            } else {
                editorWindow.find('#href').value(url).fire('change');
                editorWindow.find('#text').value(file.OrigFileName);
            }
        }
        else if (file.Category === 'video') {
            if (_.size(editorWindow.find('#source1')) !== 0) {
                editorWindow.find('#source1').value(file.FullURL).fire('change');
            } else {
                editorWindow.find('#href').value(url).fire('change');
                editorWindow.find('#text').value(file.OrigFileName);
            }
        } else if (_.size(editorWindow.find('#href')) !== 0) {
            editorWindow.find('#href').value(url).fire('change');
            editorWindow.find('#text').value(file.OrigFileName);
        }
    };

    var ExcludedSearchTags = CINC.Shared.ExcludedSearchTags = ["ignoreblacklist", "photos", "whitefeatured"];

    if (!String.prototype.includes)
    {
        String.prototype.includes = function (val, find) {
            return val.indexOf(find) > -1;
        };
    }

    var hideOtherSpaAppRegions = CINC.Shared.HideOtherSpaAppRegions = function (appRegionToShow, options) {
        options = options || {};
        var list = Backbone.Radio.channel("listingdetails");
        var lead = Backbone.Radio.channel("leaddetails");
        var wiz = Backbone.Radio.channel("wizard");
        
        if (!options.keepWizardAlive) {
            wiz.request("close");
        }

        if (options.isFullScreen === true) {
            $('.js-fullscreenhide').toggleClass('hidden', true);
            $('.contextual-container').toggleClass('full-screen-app', true);
        }
        else {
            $('.js-fullscreenhide').toggleClass('hidden', false);
            $('.contextual-container').toggleClass('full-screen-app', false);
        }

        //dismiss the listing details if we're leaving the properties app
        if (appRegionToShow.indexOf("properties") === -1 || options.forceCloseListingDetails === true) {
            list.request("close");
        }

        //dismiss the lead details if we're leaving the leads app
        if (appRegionToShow.indexOf('leads') === -1 /*&& appRegionToShow.indexOf('launchpad') === -1*/) {
            lead.request("close");
        }

        $(".spa-app").hide();
        $(".spa-leftnav").hide();
        $(".spa-centernav").hide();
        $(".spa-sidebar").addClass("hidden");
        $(".spa-sidebar-header").removeClass("active");
        $(appRegionToShow + "-sidebar-header").addClass("active");
        $(appRegionToShow + "-sidebar").removeClass("hidden");
        $(appRegionToShow + "-leftnav").show();
        $(appRegionToShow + "-centernav").show();
        $(appRegionToShow).show();
    };

    $(".js-build-lock-btn, .js-build-unlock-btn").on("click", function(e) {
        var jobName = $(this).data("build-job-name");
        var jobAction = $(this).data("build-job-action");
        var url = "/api/builds/" + jobAction + "?jobName=" + jobName;
        $.get(url, function(){
            $(".js-build-lock-btn").toggle();
            $(".js-build-unlock-btn").toggle();
        });
    });

    var addWindowLoadEvent = function(func) {
        var oldonload = window.onload;
        if (typeof window.onload !== 'function' && typeof func === 'function') {
            window.onload = func;
        }
        else {
            window.onload = function() {
                if (oldonload) {
                    oldonload();
                }
                if (func && typeof func === 'function') {
                    func();
                }
            };
        }
    };

    addWindowLoadEvent(function () {
        //support for older browsers to load images when page is first loaded
        if (!("IntersectionObserver" in window)) {
            var lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
            lazyImages.forEach(function (lazyImage) {
                if (lazyImage.getBoundingClientRect().top <= window.innerHeight) {
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.classList.remove("lazy");
                }
            });
        }
    });

    document.addEventListener("DOMContentLoaded", FireLazyLoad);

    function FireLazyLoad() {
        //lazy image loading for modern browsers
        var config = {
            rootMargin: '0px 0px 50px 0px',
            threshold: 0
        };

        if ("IntersectionObserver" in window) {
            var lazyImages1 = [].slice.call(document.querySelectorAll('img.lazy'));
            var lazyImageObserver = new IntersectionObserver(function(entries){
                entries.forEach(function(entry) {
                    if (entry.isIntersecting) {
                        var lazyImage = entry.target;
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.classList.remove("lazy");
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            }, config);

            lazyImages1.forEach(function(lazyImage) {
                lazyImageObserver.observe(lazyImage);
            });
        } else {
            //lazy image loading for ancient browsers (IE, Safari)
            var active = false;
            var lazyImages2 = [].slice.call(document.querySelectorAll('img.lazy'));
            var lazyLoad = function () {
                if (active === false) {
                    active = true;

                    setTimeout(function () {
                        lazyImages2.forEach(function (lazyImage) {
                            if ((lazyImage.getBoundingClientRect().top <= window.innerHeight &&
                                lazyImage.getBoundingClientRect().bottom >= 0) &&
                                window.getComputedStyle(lazyImage).display !== "none") {
                                lazyImage.src = lazyImage.dataset.src;
                                lazyImage.classList.remove("lazy");

                                lazyImages2 = lazyImages2.filter(function (image) {
                                    return image !== lazyImage;
                                });

                                if (lazyImages2.length === 0) {
                                    document.removeEventListener("scroll", lazyLoad);
                                    window.removeEventListener("resize", lazyLoad);
                                    window.removeEventListener("orientationchange", lazyLoad);
                                }
                            }
                        });
                        active = false;
                    },200);
                }
            };
            document.addEventListener("scroll", lazyLoad);
            window.addEventListener("resize", lazyLoad);
            window.addEventListener("orientationchange", lazyLoad);
        }
    }

}());